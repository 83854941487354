<template>
	<div>
		<el-dialog title="请假条" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="true"
			:destroy-on-close="true" @close="closeDialog" width="800px" custom-class="cus_dialog">
			<el-form :model="EditItem" ref="EditItem" label-width="120px">
				<!-- <el-row style="padding-top: 10px;">
						<el-col :span="12">
							<el-form-item label="请假类型">
								<el-select v-model="EditItem.leave_type" @change="calcTotalDay()">
									<el-option value="年假" label="年假"></el-option>
									<el-option value="事假" label="事假"></el-option>
									<el-option value="病假" label="病假"></el-option>
									<el-option value="产假" label="产假"></el-option>
									<el-option value="陪产假" label="陪产假"></el-option>
									<el-option value="婚假" label="婚假"></el-option>
									<el-option value="产检假" label="产检假"></el-option>

								</el-select>

							</el-form-item>
						</el-col>


					</el-row> -->
				<el-row style="padding-top: 10px;">
					<el-col :span="12">
						<el-form-item label="请假人">
							<el-input v-model="EditItem.stu_name" :readonly="true" style="width:100%"></el-input>

						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所在班级">
							<el-input v-model="EditItem.class_name" :readonly="true" style="width:100%"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row style="padding-top: 10px;">
					<el-col :span="12">
						<el-form-item label="开始日期">

							<el-date-picker readonly @change="calcTotalDay()" v-model="EditItem.leave_start" type="datetime"
								placeholder="选择日期" value-format="yyyy-MM-dd hh:mm:ss" style="width:100%">
							</el-date-picker>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="结束日期">
							<el-date-picker readonly @change="calcTotalDay()" v-model="EditItem.leave_end" type="datetime"
								placeholder="选择日期" value-format="yyyy-MM-dd HH:mm" style="width:100%">
							</el-date-picker>

						</el-form-item>
					</el-col>

				</el-row>
				<el-row style="padding-top: 10px;">
					<el-col :span="10">
						<el-form-item label="请假天数">
							<el-input v-model="EditItem.leave_num" readonly></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<span style="padding-left: 20px; line-height: 40px;">天</span>
					</el-col>

				</el-row>


				<el-form-item label="请假事由">
					<el-input type="textarea" readonly v-model="EditItem.leave_content" placeholder="请假事由">
					</el-input>
				</el-form-item>

				<el-row style="padding-top: 10px;">
					<el-col :span="12">
						<el-form-item label="紧急联系人">
							<el-input v-model="EditItem.remark.contact" readonly style="width:100%"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="手机">
							<el-input v-model="EditItem.remark.contactTel" readonly style="width:100%"></el-input>
						</el-form-item>
					</el-col>

				</el-row>


				<el-form-item label=" 附件" v-if="EditItem.files && EditItem.files.length > 0">
					<div style="display:inline-block; margin-right:10px" v-for="(img, idx) in EditItem.files" :key="idx">

						<el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
							:preview-src-list="[img.fileUrl || img.src]" img="cover" z-index="3000"></el-image>
					</div>
				</el-form-item>

        <el-form-item v-if="EditItem.sp_status == 2" label="拒绝原因">
          <el-input v-model="EditItem.sp_word" readonly></el-input>
        </el-form-item>



			</el-form>
			<div v-if="EditItem.sp_status == 0 && !isView" slot="footer" class="flexEnd" style="width: 100%;">
				<span style="white-space: nowrap;">审批意见：</span>
				<el-input v-model.trim="spword" size="small" type="textarea" :autosize="{ minRows: 1 }" maxlength="200"
					placeholder="审批拒绝时请填写拒绝原因哦~" style="margin-right: 20px;"></el-input>
				<el-button type="danger" @click="submitForm(2)" size="small">审批拒绝</el-button>
				<el-button type="success" @click="submitForm(1)" size="small">审批通过</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>

export default {
	components: {},
	props: ["id","isView"],
	data() {
		return {
			dialogshow: true,
			userinfo: null,
			EditItem: {
				id: 0,
				leave_type: "",
				title: "",
				leave_start: "",
				leave_start_time: "",
				leave_end: "",
				leave_end_time: "",
				leave_num: "",
				content: "",
				files: [],
				process_id: 0,
				remark: "",
				status: 1

			},
			process_code: "leave_1",
			needChoose: false,//是否需要选择下级
			choosedUser: [],//已选择的用户.
			spword: ""

		}

	},
	mounted() {
		this.getTeacheInfo()
		this.getData()
	},
	methods: {
		getTeacheInfo() {
			this.$http.post("/api/get_teacher_info").then(res => {
				this.userinfo = res.data
				this.$http.post("/api/get_sign_pic").then(res2 => {
					this.userinfo.sign_img = res2.data.handsign
				})
			})
		},
		getData() {
			this.$http.post("/api/stu_leave_one", { id: this.id }).then(res => {
				res.data.files = JSON.parse(res.data.files)
				try {
					res.data.remark = JSON.parse(res.data.remark)
				} catch (error) {
					res.data.remark = {}
				}
				this.EditItem = res.data
			})

		},
		closeDialog() {
			this.$$parent(this, "dialogshow", false);
		},
		uploadAttch(e) {

			this.EditItem.files.push(e)
		},
		calcTotalDay(lastcheck) {
			let DataInfo = this.EditItem;

			if (DataInfo.leave_start && DataInfo.leave_start_time !== '' && DataInfo.leave_end && DataInfo
				.leave_end_time !== '') {
				let start = new Date(DataInfo.leave_start).getTime();
				let end = new Date(DataInfo.leave_end).getTime();
				if (end < start) {
					this.$message({
						type: 'error',
						message: '结束日期错误！'
					});

					return false;
				} else {
					//计算日期间隔
					if (start == end) {
						if (DataInfo.leave_start_time == 0 && DataInfo.leave_end_time == 0) {
							DataInfo.leave_num = 0.5;
							this.getProcessID(1);
						} else if (DataInfo.leave_start_time == 0 && DataInfo.leave_end_time == 1) {
							DataInfo.leave_num = 1;
							this.getProcessID(1);
						} else if (DataInfo.leave_start_time == 1 && DataInfo.leave_end_time == 0) {

							this.$message({
								type: 'error',
								message: '结束时间错误！'
							});
							return false;
						} else if (DataInfo.leave_start_time == 1 && DataInfo.leave_end_time == 1) {
							DataInfo.leave_num = 0.5;
							this.getProcessID(1);
						}
					} else {
						let day = (end - start) / (24 * 60 * 60 * 1000);
						//console.log("day:" + day);
						let weekend = 0;
						for (let i = 0; i < day; i++) {
							let n = new Date(start + (24 * 60 * 60 * 1000) * i).getDay();
							if (n == 0 || n == 6) { //周末不计算
								//weekend++;
							}
						}
						if (DataInfo.leave_start_time == 0 && DataInfo.leave_end_time == 0) {
							day += 0.5;
						} else if (DataInfo.leave_start_time == 0 && DataInfo.leave_end_time == 1) {
							day += 1;
						} else if (DataInfo.leave_start_time == 1 && DataInfo.leave_end_time == 0) {
							day += 0;
						} else if (DataInfo.leave_start_time == 1 && DataInfo.leave_end_time == 1) {
							day += 0.5;
						}
						DataInfo.leave_num = day - weekend;
					}
				}
				return true;
			} else {
				if (lastcheck) {
					this.$message({
						type: 'error',
						message: '请填写完整'
					});
				}
				return false;
			}

		},

		preCheckData(e) {

			let DataInfo = this.EditItem;


			if (!DataInfo.leave_type) {

				this.$message({
					type: 'error',
					message: '请选择请假类型'
				});
				return false;
			}
			if (!DataInfo.leave_num) {

				this.$message({
					type: 'error',
					message: '请选择请假日期'
				});
				return false;
			}

			if (!DataInfo.content) {

				this.$message({
					type: 'error',
					message: '请输入请假事由'
				});
				return false;
			}
			if (!DataInfo.remark) {
				this.$message({
					type: 'error',
					message: '请输入工作安排'
				});
				return false;
			}
			if (this.needChoose && this.choosedUser.length == 0) {
				this.$message({
					type: 'error',
					message: '请选择审批人'
				});
				return false;
			}
			return true

		},
		saveForm() {

			if (!this.preCheckData()) {
				return
			}
			let EditItem = JSON.parse(JSON.stringify(this.EditItem));
			EditItem.sp_status = 0;
			EditItem.files = JSON.stringify(EditItem.files);
			EditItem.title = "[" + this.userinfo.dept_name + "]" + this.userinfo.username + "申请" + EditItem.leave_num +
				"天" + EditItem.leave_type

			this.$http.post("/api/leave_list_edit", EditItem).then(res => {
				this.$message({
					type: 'success',
					message: '保存成功'
				});
				this.$parent.getList();
				this.$parent.dialogshow = false;
			})
		},
		isChooseuser(e) {//设置是否需要选人
			this.needChoose = e
		},
		chooseUser(e) {//设置选择的人

			this.choosedUser = e

		},
		submitForm(st) {

			if (st == 2) {//审批拒绝
				if (!this.spword) {
					this.$message.error("请填写审批拒绝原因")
					return
				}
			}



			this.$confirm('确定审核?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				if (!this.taped) {
					this.taped = 1
					setTimeout(() => {
						this.taped = 0
					}, 3000);
				} else {

					return
				}
				if (!this.userinfo.sign_img) {
					this.$alert("请先在小程序设置手写签名！")
					return
				}



				this.$http.post("/api/stu_leave_form_sp", {
					id: this.EditItem.id,
					sp_status: st,
					sp_word: this.spword,
					sp_sign: this.userinfo.sign_img
				}).then(res => {
					this.$message({
						type: 'success',
						message: '审核成功!'
					});
					this.$$parent(this, "getList");
					this.$$parent(this, "dialogshow", false);


				})



			})

		}
	},
}
</script>

<style></style>
