<template>
	<div class="pageContainer targetPage flexCloumn">
		<el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
			<div class="flexStart flex_1">
				<tchtree v-model="searchForm.class_ids"></tchtree>
				<el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:300px" clearable>
					<el-select v-model="searchForm.ktype" slot="prepend" placeholder="请选择" style="width:120px">
						<el-option label="学生姓名" value="stu_name"></el-option>

					</el-select>
				</el-input>
				<el-date-picker size="small" style="width:150px;margin-left:10px" v-model="searchForm.month"
					value-format="yyyy-MM" type="month" placeholder="选择月">
				</el-date-picker>
				<el-select v-model="searchForm.sp_status" clearable placeholder="请选择" size="small"
					style="width:130px;margin-left:10px">
					<el-option label="全部" value=""></el-option>
					<el-option label="待审核" :value="0"></el-option>
					<el-option label="审核通过" :value="1"></el-option>
					<el-option label="审核拒绝" :value="2"></el-option>
				</el-select>
				<el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary" @click="() => { page.current_page = 1; getList()}">
					搜索
				</el-button>
			</div>

			<div class="flexStart"
				style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

				<el-button icon="el-icon-download" style="margin-left:10px" size="small" @click="exportXls">
					导出
				</el-button>
				<el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" style="margin-left:10px" size="small"
					type="primary" @click="tongbuJava">
					迁移老版本
				</el-button>
			</div>
		</el-row>
		<div class="dividerBar"></div>

		 <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
			<el-table-column type="index" width="65" label="序号" align="center" />

			<el-table-column prop="stu_name" label="发起人" width="100" align="center" />
			<el-table-column prop="class_name" label="班级" show-overflow-tooltip width="130" align="center" />
			<el-table-column prop="title" label="标题" show-overflow-tooltip min-width="180" align="center" />


			<el-table-column prop="leave_type" label="类型" show-overflow-tooltip width="100" align="center" />
			<el-table-column prop="leave_num" label="请假天数" show-overflow-tooltip width="100" align="center" />


			<el-table-column prop="cdate" label="请假时间" show-overflow-tooltip width="240">
				<template slot-scope="scope">
					<span style="color: blue;">{{ scope.row.leave_start }}</span>

					<span style="color: blue;"></span><br />
					<span style="color: blue;">{{ scope.row.leave_end }}</span>

				</template>

			</el-table-column>
			<el-table-column prop="leave_content" label="请假原因" show-overflow-tooltip min-width="160"
				align="center"></el-table-column>
			<el-table-column prop="cdate" label="申请时间" show-overflow-tooltip width="200" align="center" />
       <el-table-column prop="sp_status" label="审批状态" align="center">
         <template v-slot="scope">
           <el-tag v-if="scope.row.sp_status == 0" size="small">未审核</el-tag>
           <el-tag v-if="scope.row.sp_status == 1" type="success" size="small">审核通过</el-tag>
           <el-tag v-if="scope.row.sp_status == 2" type="danger" size="small">审核拒绝</el-tag>
         </template>
       </el-table-column>

			<el-table-column prop="sp_username" label="审批人" width="90" align="center" />



			<el-table-column label="操作" width="100" align="">
				<template slot-scope="scope">

					<el-tag class="pointer" type="success" size="mini" @click="viewInfo(scope.row)"
						v-if="$store.state.pmid == 221 &&scope.row.sp_status == 0 && !isGradeLeader"
						style="margin-right: 5px;">
						审批</el-tag>
					<el-tag class="pointer" type="success" size="mini" @click="viewInfo(scope.row)"
						v-if="$store.state.pmid != 221&&scope.row.sp_status == 0"
						style="margin-right: 5px;">
						审批</el-tag>

					<el-tag class="pointer" type="primary" size="mini" @click="viewInfo(scope.row,'show')">详情</el-tag>

				</template>
			</el-table-column>
		</el-table>

		<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
			:page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
			:page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
		</el-pagination>


		<formdialog v-if="dialogshow" :id="EditItem.id" :isView="isView"></formdialog>
	</div>
</template>

<script>
import formdialog from "./formdialog.vue"
import tchtree from "../com/tchTree.vue";
import {mapGetters} from "vuex";

export default {
	components: {
		formdialog, tchtree
	},
	data() {
		return {
			temphead: "//school.mmteck.cn/static/temp.png",

			dialogshow: false,
			searchForm: {
				class_ids: "",
				sp_status: "",
				ktype: "stu_name",
				keyword: "",
				month: ""

			},
			EditItem: {
				id: 0,
				group_name: "",
				needpic: "",
				outside: "",
				latesign: "",
			},
			DataList: [],

			page: {
				count: 0,
				current_page: 1,
				per_page: 50,
				total_page: 0
			},
      isView: false,

		};
	},
	mounted() {
		let year = new Date().getFullYear()
		let month = new Date().getMonth() + 1
		this.searchForm.month = year + '-' + month
		this.getList()
	},
  computed: {
    ...mapGetters(['isGradeLeader']),
  },
	methods: {
		getList() {
			this.$http.post("/api/stu_leave_list", {
				page: this.page.current_page,
				pagesize: this.page.per_page,
				ktype: this.searchForm.ktype,
				keyword: this.searchForm.keyword,
				sp_status: this.searchForm.sp_status,
				month: this.searchForm.month,
				class_ids: this.searchForm.class_ids

			}).then(res => {
				this.DataList = res.data.data
				this.page = res.data.page
			})
		},
		handlePageChange(page) {
			this.page.current_page = page
			this.getList()
		},
		handleSizeChange(val) {
			this.page.current_page = 1;
			this.page.per_page = val;
			this.getList()
		},
		addNew() {
			this.EditItem.id = 0;
			this.dialogshow = true;
		},
		viewInfo(row,type) {
      this.isView = type === 'show'
			this.EditItem = row
			this.dialogshow = true;
		},
		exportXls() {
			this.$http.post("/api/stu_leave_list_export", {
				page: this.page.current_page,
				pagesize: 2000,
				ktype: this.searchForm.ktype,
				keyword: this.searchForm.keyword,
				sp_status: this.searchForm.sp_status,
				month: this.searchForm.month,

			}).then(res => {
				if (res.data.url) {
					window.open(res.data.url)
				}
			})
		},
		tongbuJava() {
			this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let loadingInstance = this.$loading({
					lock: true,
					text: "已发送请求，请稍等...",
					spinner: 'el-icon-loading'
				})
				this.$http.post("/api/sync_stu_leave", {}).then(res => {
					loadingInstance.close()
					this.$message.success('后台同步中，请刷新查看')
					this.getList();
				}).catch(err => {
					this.$message.error('同步失败')
					loadingInstance.close()
				})

			}).catch(() => {

			});
		}
	},
}
</script>

<style scoped="scoped">
.spuser {
	display: inline-block;
	border: 1px solid rgb(255, 148, 62);


	padding: 2px;
	margin-right: 10px;
	margin-bottom: 5px;
	border-radius: 4px;
	padding: 4px 10px;
}

.spuser img {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
}
</style>
